@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";
@import "assets/css/default/app.min.css";

body{
  background-color: #fff!important;
}

.content{
  padding-top: 0px!important;
}

/* SIDEBAR */
.navbar-brand{
  padding: 0!important;
}

.nav>li>a i img{
  width: 15px;
  height: 15px;
}
.sidebar {
  padding-top: 60px!important;
  background: #221c46;
}

.page-header-fixed {
  padding-top: 60px!important
}

.page-header-fixed .header {
  align-items: center;
  height: 60px;
}

.header .navbar-brand img {
  height: 60px!important;
  max-height: max-content!important;
  max-width: max-content!important;
}

.sidebar .nav>li.nav-profile{
  background: #221c46!important;
  overflow: visible!important;
}

.sidebar .nav {
  background: #221c46!important;
}
.scrollbar-container {
  background: #221c46!important;
}

.sidebar .nav>li>a {
  color: #fff;
}

.cwveSU small{
  color: #fff!important;
}

.sidebar .nav>li.active>a{
  background: #221c46!important;
  /* color: #FF7375!important; */
}

.sidebar .nav .sub-menu>li>a{
  color: #fff;
}

.sidebar .nav>li.active>a i{
  color: #221c46!important;
}
.sidebar .nav>li.active>a span{
  color: #FF7375!important;
  font-weight: bold;
}

.sidebar .sub-menu>li.active>a{
  /* background: #FBDDDA!important; */
  color: #FF7375!important;
  /* font-weight: bold; */
}

.sidebar .sub-menu>li>a :hover{
  color: #FF7375!important;
}

 .sidebar .nav>li>a :hover{
  color: #FF7375!important;
}

.sidebar .sub-menu>li:before{
  background: #221c46;
}
.sidebar .sub-menu>li>a:after{
  background: #221c46;
}

.sidebar .sidebar-minify-btn{
  background: #221c46;
}

.sidebar .sidebar-minify-btn:hover, .sidebar .sidebar-minify-btn:active{
  background: #221c46!important;
}
.sidebar .sidebar-minify-btn:focus, .sidebar .sidebar-minify-btn:hover{
  background: #221c46!important;
}

.float-sub-menu-container .float-sub-menu-arrow {
  background: #221c46;
}

.float-sub-menu .closed:before{
  border-color: #221c46!important;
  color: #221c46!important;
  background: #221c46!important;
}

.float-sub-menu .closed:after{
  border-color: #fff!important;
  color: #fff!important;
}

.float-sub-menu>li>a:after {
  background: #221c46!important;
}

.float-sub-menu>li.active:after {
  border-color: #fff!important;
}

.float-sub-menu>li:before {
  background-color: #221c46!important;
}

.float-sub-menu>li.active>a {
  color: #FF7375!important;
}

/* ////////////////////////////////// */

.sub-menu .closed:before{
  border-color: #221c46!important;
  color: #221c46!important;
  background: #221c46!important;
}

.sub-menu .closed:after{
  border-color: #fff!important;
  color: #fff!important;
}

.sub-menu>li>a:after {
  background: #221c46!important;
}

.sub-menu>li.active:after {
  border-color: #fff!important;
}

.sub-menu>li:before {
  background-color: #221c46!important;
}

.sub-menu>li.active>a {
  color: #FF7375!important;
}
/* /////////////////////////////////////// */

.coBELi {
  background-color: #221c46!important;
  color: #fff!important
}

.float-sub-menu .closed a {
  color: #fff!important
}

.float-sub-menu .closed a:hover {
  color: #f56666!important
}

.float-sub-menu-container .float-sub-menu{
  background: #221c46;
  color: #fff!important;
}

.float-sub-menu-container .float-sub-menu:hover{
  color: #f56666
}

/* .sidebar .sub-menu>li>a{
  padding-left: 40px!important;
}

.sidebar .sub-menu{
  padding: 5px 0 10px 0px;
} */

/* .sidebar .sub-menu>li:after{
  height: 0px;
  width: 0px;
  color: #221c46!important;
  border: 0;
  margin-right: 10px;
}


.sidebar li>a .caret:before{
  opacity: 1;
}

.sidebar .sidebar-minify-btn{
  background-color: #221c46;
}

.sidebar .sub-menu>li{
  position: static;
} */

/* ///////////////////// */

.sidebar .sub-menu>li.active:after{
  border-color: #fff;
}

.sidebar .sub-menu>li:after{
  border-color: #fff;
}

.bWJqfO small{
  color: #fff!important
}

/* TOOLTIP HEADER */

.dropdown-item:focus, .dropdown-item:hover{
  background: #FBDDDA!important;
  font-weight: bold;
}
.dropdown-item{
  color: #221c46!important;
}


/* GRAFICOS */

.apexcharts-legend-marker {
  border-radius: 0!important;
}
.panel.panel-inverse>.panel-heading{
   background: #221c46!important;
}
.panel .panel-heading{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.apexcharts-legend-text{
  color: #221c46!important;
}


/* INPUTS */
.css-e56m7-control{
  border-color:#dee2e6!important
}


input[type=checkbox], input[type=radio]{
  accent-color: #f56665;
  /* width: 15px;
  height: 15px; */
  border-radius: 0!important;
}

input[type=checkbox]:hover, input[type=radio]{
  accent-color: #f56666
}


input[type=checkbox] {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;
}

input[type=checkbox]:before {
	font-family: "FontAwesome";
    content: "\f00c";
    font-size: 15px;
    color: transparent !important;
    width: 20px;
    height: 20px;
    border: 1px solid #221c46;
}

input[type=checkbox]:checked:before {
  background: #ff7375;
	color: #fff !important;
}

input[type=checkbox]:checked:before {
  background: #ff7375;
	color: #fff !important;
}

input[type=checkbox][disabled]:checked:before{
  background: #D1CFD7!important;
  border: 1px solid #908C99!important;
}
input[type=checkbox][disabled]:before{
  border: 1px solid #908C99!important;
}

.swal-overlay .swal-modal .swal-icon--warning, .swal-overlay .swal-modal .swal-icon--warning .swal-icon--warning__body, .swal-overlay .swal-modal .swal-icon--warning .swal-icon--warning__dot, .swal-overlay .swal-modal .swal-icon--warning .swal-icon--warning__body, .swal-overlay .swal-modal .swal-icon--warning .swal-icon--warning__dot {
  border-color: #B26019!important;
}

.swal-title:not(:last-child) {
  color: #221c46;
  font-size: 20px;
}

.swal-button{
  background-color: #ff7375!important;
  border: 2px solid #221C46!important;
  color: #221C46!important;
  border-radius: 0!important
}
.swal-button--cancel{
  background-color: #fff!important;
  border: 2px solid #221C46!important;
  color: #221C46!important;
}

.swal-button--cancel:hover, .swal-button:hover{
  background-color:#FBDDDA!important;
}

.swal-button:focus {
  box-shadow: none;
}

.swal-modal {
  border-radius:0!important;
}

.react-datepicker{
  border-radius: 0!important
}

.react-datepicker-wrapper {
  input {
    background: #fff;
    color: #000;
    font-size: 14px;
    padding: 10px;
    color: #363636;
    font-weight: 500;
    width: 100% !important;
    height: 20px !important;
    border: solid 1px #d9e0e7;
    border-radius: 5px;
    font-size: 11px !important;
  }
}

.react-datepicker__day--outside-month {
  color: #cfc1c1bd !important;
}

.react-datepicker__day--outside-month:hover {
  color: #544a4abd !important;
  /* pointer-events: none; */
}

.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day{
  border-radius: 0!important;
  /* padding: 0px!important;
  line-height: none!important; */
  line-height: 30px!important;
  border: 1px solid #d7d7d7!important;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today {
  background: #FF7375;
  color: #221c46;
  outline: none;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
  background: #FF7375;
  color: #221c46!important;
}

.bnymVP main table .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover, .bnymVP main table .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus, .bnymVP main table .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today{
  color:#fff!important
}

.react-datepicker__day--in-range{
  background-color: #fff!important;
  background: #FF7375!important;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
  background-color: #cfc1c1bd
 }

 .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
 }

 .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
  background-color: #E0E0E4!important;
  color: #221c46!important
 }

 .react-datepicker__close-icon::after{
  background-color: #FF7375!important;
 }

 .Toastify__toast--error {
    background: #B30000;
  }
  .Toastify__toast--success {
    background: #006245;
  }

  .css-g8u311-control {
    background-color: #D1CFD7!important;
    color: #908C99!important
  }

  .css-tlfecz-indicatorContainer, .css-107lb6w-singleValue, .css-g1d714-ValueContainer{
    color: #908C99!important
  }
  .panel-heading-btn img{
    width: 12px!important;
    height: 12px!important;
  }

  .css-ql68v7-control{
    border-radius: 0!important
  }

  .css-1j79x1m-control{
    border-radius: 0!important
  }

  .infoImage {
    color: #221c46;
    text-align: left;
    margin-bottom: 0;
    margin-top: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
  }
