.react-datepicker__day {
  color: #000 !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__day--highlighted-feriados {
  background: #221C46;
  color: #fff!important
}

.react-datepicker__day--highlighted-programados {
  background: #ff7375;
  color: #fff!important
}

.react-datepicker__day--highlighted-datadesejada {
  background: #50e66e;
}

/* .react-datepicker__close-icon {
  margin-right: 10px;
} */

.react-datepicker__navigation-icon--previous {
  right: -30px;
}
.react-datepicker__navigation-icon--next{
  left: -30px;
}
